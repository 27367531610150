import $ from 'jquery';
import whatInput from 'what-input';

window.$ = $;

import Foundation from 'foundation-sites';
// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


$(document).foundation();

$(function () {
  var off = $('.to-page-top').offset();
  console.log('top: ' + off.top);
  console.log('left: ' + off.left);

  var toPageTop = $('.to-page-top__p');
  $(window).scroll(function () {
      if ($(this).scrollTop() > off.top) {
        toPageTop.fadeIn();
      } else {
        toPageTop.fadeOut();
      }
  });

});
